(function() {

    'use strict';

    App.Slider = function(container, options) {
        this.opts = $.extend(this.defaults, options);
        this.container = container;
        this.slides = this.container.find(this.opts.slideSelector);

        if(this.slides.length > 1) {
            this.initSlider();
        }
    };

    App.Slider.prototype.defaults = {
        autoplay: 6000,
        effect: 'fade',
        nextButton: '.swiper-button-next',
        pagination: '.swiper-pagination',
        paginationClickable: true,
        prevButton: '.swiper-button-prev',
        slideSelector: '.swiper-slide',
        spaceBetween: 0,
        speed: 400
    };

    App.Slider.prototype.initSlider = function() {
        new Swiper(this.container, this.opts);
    };

}());